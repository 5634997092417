import { ReactElement, useState, useEffect } from "react"
import { TextField } from "../../../global/forms/text-field"
import { BaseModal } from "./base-modal"

export type EditInitialRequestValue = {
  indicativeOffer: number
  note: string
  bioBuyer: string
}

type EditInitialRequestProps = {
  open?: boolean
  submitting?: boolean
  initialValue: EditInitialRequestValue
  onSubmit: (value: EditInitialRequestValue) => void
  onCancel: () => void
}

export const EditInitialRequestModal: React.FC<EditInitialRequestProps> = ({
  open,
  submitting,
  initialValue,
  onSubmit,
  onCancel,
}): ReactElement => {
  const [internalValue, setInternalValue] = useState(initialValue)

  useEffect(() => {
    setInternalValue(initialValue)
  }, [initialValue])

  return (
    <BaseModal
      title="Edit request"
      submitLabel="Save"
      open={open}
      submitting={submitting}
      onSubmit={() => onSubmit(internalValue)}
      onCancel={onCancel}
    >
      <div>
        <h3 className="block text-sm font-medium text-gray-900 mb-2">
          Indicative offer
        </h3>
        <TextField
          value={internalValue.indicativeOffer.toString()}
          onChange={(value) =>
            setInternalValue({
              ...internalValue,
              indicativeOffer: parseInt(value ?? "") ?? 0,
            })
          }
          className="w-full"
        />
      </div>
      <div>
        <h3 className="block text-sm font-medium text-gray-900 mb-2">
          What appeals about the property
        </h3>
        <TextField
          value={internalValue.note}
          rows={5}
          onChange={(value) =>
            setInternalValue({
              ...internalValue,
              note: value ?? "",
            })
          }
          className="w-full"
        />
      </div>
      <div>
        <h3 className="block text-sm font-medium text-gray-900 mb-2">
          Buyer bio
        </h3>
        <TextField
          value={internalValue.bioBuyer}
          rows={5}
          onChange={(value) =>
            setInternalValue({
              ...internalValue,
              bioBuyer: value ?? "",
            })
          }
          className="w-full"
        />
      </div>
    </BaseModal>
  )
}
