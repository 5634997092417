import { ReactElement, useState, useEffect } from "react"
import { BaseModal } from "./base-modal"
import { DateField } from "../../../global/forms/date-field"
import { TextField } from "../../../global/forms/text-field"

type EditViewingProps = {
  open?: boolean
  submitting?: boolean
  value?: { id: string; dateViewing: string; additionalAttendees?: string }
  onSubmit: (value: { id: string; dateViewing: string }) => void
  onCancel: () => void
}

export const EditViewingModal: React.FC<EditViewingProps> = ({
  open,
  submitting,
  value,
  onSubmit,
  onCancel,
}): ReactElement => {
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  return (
    <BaseModal
      title="Edit viewing"
      submitLabel="Save"
      open={open}
      submitting={submitting}
      onSubmit={() => {
        if (!internalValue) return

        onSubmit(internalValue)
      }}
      onCancel={onCancel}
    >
      <div>
        <h3 className="block text-sm font-medium text-gray-900 mb-2">Date</h3>
        <DateField
          value={internalValue?.dateViewing}
          onChange={(value) => {
            if (!internalValue || !value) return

            setInternalValue({
              ...internalValue,
              dateViewing: value,
            })
          }}
        />
      </div>
      <div>
        <h3 className="block text-sm font-medium text-gray-900 mb-2">
          Additional attendees
        </h3>
        <TextField
          value={internalValue?.additionalAttendees}
          rows={5}
          onChange={(value) => {
            if (!internalValue) return

            setInternalValue({
              ...internalValue,
              additionalAttendees: value,
            })
          }}
          className="w-full"
        />
      </div>
    </BaseModal>
  )
}
