import { ReactElement, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import { Header } from "../../global/header"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { Container } from "../../global/container"
import { FormPanel } from "../../global/forms/form-panel"
import {
  cancelApprove,
  cancelEditingInitialRequest,
  cancelEditingNote,
  cancelEditingViewing,
  cancelReject,
  deleteViewingRequest,
  editInitialRequest,
  editNote,
  editViewing,
  fetchViewingRequest,
  review,
  startApprove,
  startEditingInitialRequest,
  startEditingNote,
  startEditingViewing,
  startReject,
} from "../../../redux/slices/viewing-request-details"
import { FieldTitle } from "../../global/forms/field-title"
import { formatVerifiedFlag } from "../../../util/users"
import { formatFundingPosition } from "../../../util/offers"
import { ActivityItem } from "./activity-item"
import {
  AdminRequestDetailsResponse_ActivityItem,
  AdminRequestDetailsResponse_AdminAction,
  AdminRequestDetailsResponse_AdminActionType,
} from "../../../ostrich/rpc/users/viewings/v2_pb"
import {
  EditInitialRequestModal,
  EditInitialRequestValue,
} from "./modals/edit-initial-request-modal"
import { ApproveModal } from "./modals/approve-modal"
import { RejectModal } from "./modals/reject-modal"
import { EditNoteModal } from "./modals/edit-note-modal"
import { formatDateTime } from "../../../util/dates"
import { ActionButton } from "./action-button"
import { EditViewingModal } from "./modals/edit-viewing-modal"

export const ViewingRequestDetails: React.FC = (): ReactElement | null => {
  let { buyerId, propertyId } = useParams()

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!buyerId || !propertyId) return

    dispatch(fetchViewingRequest({ buyerId, propertyId }))
  }, [dispatch, buyerId, propertyId])

  let {
    viewingRequest,
    submitting,
    modals,
    editNoteAction,
    editViewingData,
    reviewAction,
  } = useAppSelector((state) => state.viewingRequestDetails)

  const handleAction = (
    item: AdminRequestDetailsResponse_ActivityItem,
    action: AdminRequestDetailsResponse_AdminAction,
  ) => {
    if (!buyerId || !propertyId) return

    switch (action.type) {
      case AdminRequestDetailsResponse_AdminActionType.APPROVE:
        dispatch(
          startApprove({
            item,
            emailsBuyer: action.emailsBuyer,
            emailsSeller: action.emailsSeller,
          }),
        )
        break

      case AdminRequestDetailsResponse_AdminActionType.REJECT:
        dispatch(
          startReject({
            item,
            emailsBuyer: action.emailsBuyer,
            emailsSeller: action.emailsSeller,
          }),
        )
        break

      case AdminRequestDetailsResponse_AdminActionType.EDIT_NEW_REQUEST:
        dispatch(startEditingInitialRequest())
        break

      case AdminRequestDetailsResponse_AdminActionType.EDIT_NOTE:
        dispatch(
          startEditingNote({
            id: item.id,
            note: item.payload["note"],
          }),
        )
        break

      default:
        return
    }
  }

  const handleEditInitialRequest = (value: EditInitialRequestValue) => {
    if (!buyerId || !propertyId) return

    dispatch(
      editInitialRequest({
        buyerId,
        propertyId,
        ...value,
      }),
    )
  }

  const handleEditNote = (actionId: string, note: string) => {
    if (!buyerId || !propertyId) return

    dispatch(
      editNote({
        buyerId,
        propertyId,
        actionId,
        note,
      }),
    )
  }

  const handleApprove = (
    actionId: string,
    sendBuyerEmails?: boolean,
    sendSellerEmails?: boolean,
  ) => {
    if (!buyerId || !propertyId) return

    dispatch(
      review({
        buyerId,
        propertyId,
        actionId,
        sendBuyerEmails,
        sendSellerEmails,
        approved: true,
      }),
    )
  }

  const handleReject = (actionId: string) => {
    if (!buyerId || !propertyId) return

    dispatch(review({ buyerId, propertyId, actionId, approved: false }))
  }

  const handleDelete = () => {
    if (!buyerId || !propertyId) return

    dispatch(
      deleteViewingRequest({
        buyerId,
        propertyId,
      }),
    )
  }

  const handleEditViewing = (viewing: {
    id: string
    dateViewing: string
    additionalAttendees?: string
  }) => {
    if (!buyerId || !propertyId) return

    dispatch(
      editViewing({
        buyerId,
        propertyId,
        viewingId: viewing.id,
        dateViewing: viewing.dateViewing,
        additionalAttendees: viewing.additionalAttendees,
      }),
    )
  }

  if (!viewingRequest || !viewingRequest.buyer || !viewingRequest.property) {
    return null
  }

  const hasActivity = viewingRequest.activity.length > 0

  return (
    <>
      <Header
        title={`${viewingRequest.buyer.name}`}
        breadcrumbs={[
          { label: "Viewing Requests", url: "/viewing-requests/" },
          { label: "Viewing Request" },
        ]}
        secondaryButton={
          viewingRequest.actions
            .map((a) => a.type)
            .includes(AdminRequestDetailsResponse_AdminActionType.DELETE)
            ? {
                label: "Delete Viewing Request",
                onClick: handleDelete,
              }
            : undefined
        }
        dateCreated={viewingRequest.dateCreated}
        dateUpdated={viewingRequest.dateUpdated}
      />
      <Container>
        <div className="mb-5 flex flex-col gap-y-5 xl:grid xl:grid-cols-12 xl:gap-x-5">
          <div className="col-span-4 row-span-2">
            <FormPanel
              title="Viewing Request"
              className="grid grid-cols-3"
              expandHeight
            >
              {hasActivity && (
                <>
                  <div className="col-span-3 md:col-span-1 border-b pb-6 mb-6">
                    <label className="block text-sm font-medium text-gray-700">
                      Indicative Offer
                    </label>
                    <div className="mt-1">
                      £{viewingRequest.indicativeOffer.toLocaleString()}
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-1 border-b pb-6 mb-6">
                    <label className="block text-sm font-medium text-gray-700">
                      Funding Position
                    </label>
                    <div className="mt-1">
                      {formatFundingPosition(viewingRequest.fundingPosition)}
                    </div>
                  </div>
                  <div className="col-span-3 md:col-span-1 border-b pb-6 mb-6">
                    <label className="block text-sm font-medium text-gray-700">
                      Home to Sell First?
                    </label>
                    <div className="mt-1">
                      {viewingRequest.homeSeller ? "Yes" : "No"}
                    </div>
                  </div>
                </>
              )}
              <div className="col-span-3">
                <label className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <div className="mt-1 flex items-center">
                  {hasActivity ? viewingRequest.status : "No activity"}
                  {viewingRequest.reviewRequired && (
                    <span className="inline-flex items-center rounded-full text-xs font-medium ml-2 px-2.5 py-1 bg-yellow-100 text-yellow-800">
                      Review required
                    </span>
                  )}
                </div>
              </div>
            </FormPanel>
          </div>
          <div className="col-span-4 row-span-2">
            <FormPanel title="Property" expandHeight>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Display Address
                </label>
                <div className="mt-1">
                  <Link
                    to={`/properties/${viewingRequest.property.id}/`}
                    className="font-medium text-blue-600"
                  >
                    {viewingRequest.property.address}
                  </Link>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Price Guide
                </label>
                <div className="mt-1">
                  £{viewingRequest.property.guidePrice.toLocaleString()}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Building Type
                </label>
                <div className="mt-1">
                  {viewingRequest.property.buildingType}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Interior Size
                </label>
                <div className="mt-1">
                  {viewingRequest.property.interiorSize.toLocaleString()} sq ft
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Bedrooms
                </label>
                <div className="mt-1">{viewingRequest.property.bedrooms}</div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Bathrooms
                </label>
                <div className="mt-1">{viewingRequest.property.bathrooms}</div>
              </div>
            </FormPanel>
          </div>
          <div className="col-span-4">
            <FormPanel title="Buyer" className="grid grid-cols-2 gap-6">
              <div className="col-span-2 md:col-span-1">
                <FieldTitle
                  title="Name"
                  extraActions={[
                    {
                      key: "view-user",
                      label: "View full details",
                      href: `/users/${viewingRequest.buyer.id}/`,
                      external: true,
                    },
                  ]}
                />
                <div className="mt-1">
                  <Link
                    to={`/users/${viewingRequest.buyer.id}/`}
                    target="_blank"
                  >
                    {viewingRequest.buyer.name}
                  </Link>
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  E-mail
                </label>
                <div className="mt-1 break-all">
                  {viewingRequest.buyer.email}
                </div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Phone
                </label>
                <div className="mt-1">{viewingRequest.buyer.phoneNumber}</div>
              </div>
              <div className="col-span-2 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Identity Verified?
                </label>
                <div className="mt-1">
                  {formatVerifiedFlag(viewingRequest.buyer.verified)}
                </div>
              </div>
            </FormPanel>
          </div>
          <div className="col-span-4">
            <FormPanel
              title={`Seller${viewingRequest.sellers.length > 1 ? "s" : ""}`}
              className="grid grid-cols-3"
              expandHeight
            >
              <div className="col-span-3 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <div className="mt-1">
                  {viewingRequest.sellers.map((seller, i) => (
                    <p key={`seller-${i}`}>{seller.name}</p>
                  ))}
                </div>
              </div>
              <div className="col-span-3 md:col-span-1">
                <label className="block text-sm font-medium text-gray-700">
                  E-mail
                </label>
                <div className="mt-1">
                  {viewingRequest.sellers.map((seller, i) => (
                    <p key={`seller-${i}`}>{seller.email}</p>
                  ))}
                </div>
              </div>
            </FormPanel>
          </div>
        </div>
        {hasActivity && (
          <div className="mb-5 flex flex-col gap-y-5 xl:grid xl:grid-cols-3 xl:gap-x-5">
            <FormPanel title="Activity" className="flex flex-col" expandHeight>
              <div className="flow-root">
                <ul>
                  {viewingRequest.activity.map((item) => (
                    <ActivityItem
                      key={`item-${item.id}`}
                      item={item}
                      onAction={handleAction}
                    />
                  ))}
                </ul>
              </div>
            </FormPanel>
            {viewingRequest.viewings?.length > 0 && (
              <FormPanel title="Viewings" expandHeight>
                <table className="col-span-2 min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-2/5 py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="w-2/5 py-3.5 px-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                      >
                        Additional attendees
                      </th>
                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0"
                      >
                        <span className="sr-only">View</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {viewingRequest.viewings.map((viewing) => (
                      <tr key={viewing.id}>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-700 sm:pl-6 md:pl-0">
                          <div className="flex gap-x-2">
                            {formatDateTime(viewing.dateViewing)}
                            {viewing.status === "CA" && (
                              <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                Cancelled
                              </span>
                            )}
                          </div>
                        </td>
                        <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-700 sm:pl-6 md:pl-0">
                          {viewing.additionalAttendees ?? (
                            <span className="text-gray-500">N/A</span>
                          )}
                        </td>
                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-6 md:pr-0">
                          <div className="flex gap-x-2">
                            <ActionButton
                              type={
                                AdminRequestDetailsResponse_AdminActionType.EDIT_VIEWING
                              }
                              onClick={() => {
                                dispatch(
                                  startEditingViewing({
                                    id: viewing.id,
                                    dateViewing: viewing.dateViewing,
                                    additionalAttendees:
                                      viewing.additionalAttendees,
                                  }),
                                )
                              }}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </FormPanel>
            )}
          </div>
        )}
      </Container>
      <ApproveModal
        action={reviewAction}
        open={modals.approve}
        verified={viewingRequest.buyer.verified === "VE"}
        onSubmit={handleApprove}
        onCancel={() => dispatch(cancelApprove())}
      />
      <RejectModal
        action={reviewAction}
        open={modals.reject}
        onSubmit={handleReject}
        onCancel={() => dispatch(cancelReject())}
      />
      <EditInitialRequestModal
        open={modals.editInitialRequest}
        submitting={submitting}
        initialValue={{
          indicativeOffer: viewingRequest.indicativeOffer,
          note: viewingRequest.note,
          bioBuyer: viewingRequest.bioBuyer,
        }}
        onSubmit={handleEditInitialRequest}
        onCancel={() => {
          dispatch(cancelEditingInitialRequest())
        }}
      />
      <EditNoteModal
        action={editNoteAction}
        open={modals.editNote}
        submitting={submitting}
        onSubmit={handleEditNote}
        onCancel={() => {
          dispatch(cancelEditingNote())
        }}
      />
      <EditViewingModal
        value={editViewingData}
        open={modals.editViewing}
        submitting={submitting}
        onSubmit={handleEditViewing}
        onCancel={() => {
          dispatch(cancelEditingViewing())
        }}
      />
    </>
  )
}
