import { Transition, Dialog } from "@headlessui/react"
import { ReactElement, Fragment, useRef, ReactNode } from "react"

interface BaseModalProps {
  title: string
  submitLabel: string
  alert?: string
  open?: boolean
  submitting?: boolean
  children: ReactNode
  onSubmit: () => void
  onCancel: () => void
}

export const BaseModal: React.FC<BaseModalProps> = ({
  title,
  submitLabel,
  alert,
  open,
  submitting,
  children,
  onSubmit,
  onCancel,
}): ReactElement => {
  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open ?? false} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 bg-opacity-75 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="w-full text-center sm:text-left">
                  <Dialog.Title
                    as="h3"
                    className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6 font-semibold text-gray-900 flex items-center gap-2 rounded-t-lg"
                  >
                    {title}
                    {alert && (
                      <span className="inline-flex items-center rounded-full bg-orange-100 px-2.5 py-0.5 text-xs font-medium text-orange-800">
                        {alert}
                      </span>
                    )}
                  </Dialog.Title>
                  <div className="flex flex-col gap-y-5 px-4 sm:px-6 py-4 bg-gray-50">
                    {children}
                  </div>
                </div>
                <div className="sm:flex sm:flex-row-reverse border-t border-gray-200 bg-white px-4 py-5 sm:px-6 rounded-b-lg">
                  <button
                    type="button"
                    className={`inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none sm:ml-3 sm:w-auto sm:text-sm transition-colors ${submitting ? "bg-blue-100" : "bg-blue-600 hover:bg-blue-700"}`}
                    disabled={submitting}
                    onClick={onSubmit}
                  >
                    {submitLabel}
                  </button>
                  <div className="flex-grow" />
                  <button
                    type="button"
                    className={`mt-3 inline-flex w-full justify-center rounded-md border bg-white px-4 py-2 text-base font-medium shadow-sm focus:outline-none sm:mt-0 sm:w-auto sm:text-sm transition-colors ${submitting ? "border-gray-100 text-gray-200" : "hover:bg-gray-50 border-gray-300 text-gray-700"}`}
                    disabled={submitting}
                    onClick={onCancel}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
