import {
  CheckCircleIcon,
  PencilIcon,
  XCircleIcon,
} from "@heroicons/react/solid"
import { AdminRequestDetailsResponse_AdminActionType } from "../../../ostrich/rpc/users/viewings/v2_pb"
import { createElement } from "react"

interface ActionButtonProps {
  type: AdminRequestDetailsResponse_AdminActionType
  onClick: () => void
}

const getLabel = (type: AdminRequestDetailsResponse_AdminActionType) => {
  switch (type) {
    case AdminRequestDetailsResponse_AdminActionType.APPROVE:
      return "Approve"

    case AdminRequestDetailsResponse_AdminActionType.REJECT:
      return "Reject"

    case AdminRequestDetailsResponse_AdminActionType.EDIT_NEW_REQUEST:
    case AdminRequestDetailsResponse_AdminActionType.EDIT_NOTE:
    case AdminRequestDetailsResponse_AdminActionType.EDIT_VIEWING:
      return "Edit"

    default:
      return null
  }
}

const getColourClasses = (
  type: AdminRequestDetailsResponse_AdminActionType,
) => {
  switch (type) {
    case AdminRequestDetailsResponse_AdminActionType.APPROVE:
      return "text-emerald-500 hover:bg-emerald-50"

    case AdminRequestDetailsResponse_AdminActionType.REJECT:
      return "text-red-500 hover:bg-red-50"

    case AdminRequestDetailsResponse_AdminActionType.EDIT_NEW_REQUEST:
    case AdminRequestDetailsResponse_AdminActionType.EDIT_NOTE:
    case AdminRequestDetailsResponse_AdminActionType.EDIT_VIEWING:
      return "text-indigo-500 hover:bg-indigo-50"
  }
}

const getIcon = (type: AdminRequestDetailsResponse_AdminActionType) => {
  switch (type) {
    case AdminRequestDetailsResponse_AdminActionType.APPROVE:
      return CheckCircleIcon

    case AdminRequestDetailsResponse_AdminActionType.REJECT:
      return XCircleIcon

    case AdminRequestDetailsResponse_AdminActionType.EDIT_NEW_REQUEST:
    case AdminRequestDetailsResponse_AdminActionType.EDIT_NOTE:
    case AdminRequestDetailsResponse_AdminActionType.EDIT_VIEWING:
      return PencilIcon
  }
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  type,
  onClick,
}) => {
  const icon = getIcon(type)

  return (
    <button
      type="button"
      className={`inline-flex items-center gap-x-1.5 rounded-md bg-white px-2.5 py-1.5 text-sm font-medium shadow-sm ring-1 ring-inset ring-gray-300 ${getColourClasses(type)}`}
      onClick={onClick}
    >
      {icon && createElement(icon, { className: "size-5 -ml-0.5" })}
      <span className="text-gray-600">{getLabel(type)}</span>
    </button>
  )
}
