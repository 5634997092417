import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"

import { AdminListRequestsResponse } from "../../ostrich/rpc/users/viewings/v2_pb"
import { BaseFilters } from "../../types/filters"

export interface ViewingRequestFilters extends BaseFilters {
  status?: string[]
  includeUnverifiedUsers?: boolean
}

export const decodeFilters = (
  searchParams: URLSearchParams,
): ViewingRequestFilters => {
  const filters: ViewingRequestFilters = {}

  const status = searchParams
    .get("status")
    ?.split(",")
    .filter((s) => s.length)

  if (status?.length) {
    filters.status = status
  }

  const includeUnverifiedUsers = searchParams.get("includeUnverifiedUsers")

  if (includeUnverifiedUsers) {
    filters.includeUnverifiedUsers = true
  }

  const search = searchParams.get("search")

  if (search) {
    filters.searchQuery = search
  }

  const page = searchParams.get("page")

  if (page) {
    filters.page = parseInt(page)
  }

  return filters
}

export const encodeFilters = (
  filters: ViewingRequestFilters,
): URLSearchParams => {
  const params = new URLSearchParams()

  if (filters.status?.length) {
    params.set("status", filters.status.join(","))
  }

  if (filters.includeUnverifiedUsers) {
    params.set("includeUnverifiedUsers", "true")
  }

  if (filters.searchQuery) {
    params.set("search", filters.searchQuery)
  }

  if (filters.page) {
    params.set("page", filters.page.toString())
  }

  return params
}

const initialState: AdminListRequestsResponse = {
  viewingRequests: [],
  pagination: {
    totalCount: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 20,
  },
}

export const fetchViewingRequests = createAsyncThunk(
  "viewingRequestsList/fetchViewingRequests",
  async (filters: ViewingRequestFilters, thunkAPI) => {
    const encodedFilters = encodeFilters(filters)

    const response = await fetch(
      `${getEndpoint()}/viewing-requests/?${encodedFilters.toString()}`,
      {
        cache: "no-cache",
        credentials: "include",
      },
    )

    const json = (await response.json()) as AdminListRequestsResponse

    return json
  },
)

export const viewingRequestListSlice = createSlice({
  name: "viewingRequestList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchViewingRequests.fulfilled, (state, action) => {
      state.viewingRequests = action.payload.viewingRequests
      state.pagination = action.payload.pagination || initialState.pagination
    })
  },
})

export const viewingRequestListReducer = viewingRequestListSlice.reducer
