import { ReactElement, useEffect, useState } from "react"
import { Header } from "../../global/header"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { useSearchParams } from "react-router-dom"
import {
  decodeFilters,
  encodeFilters,
  fetchViewingRequests,
  ViewingRequestFilters,
} from "../../../redux/slices/viewing-requests-list"
import { Container } from "../../global/container"
import { ViewingRequestFiltersPanel } from "./viewing-request-filters"
import { Table } from "../../global/tables/table"
import { ViewingRequestRow } from "./viewing-request-row"

export const ViewingRequestList: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filters, setFilters] = useState<ViewingRequestFilters>({})

  const { viewingRequests, pagination } = useAppSelector(
    (state) => state.viewingRequestList,
  )

  useEffect(() => {
    const filters = decodeFilters(searchParams)
    dispatch(fetchViewingRequests(filters))
    setFilters(filters)
  }, [dispatch, searchParams])

  const handleChangeFilters = (newFilters: ViewingRequestFilters) => {
    const hasOtherFilterChanges = Object.keys(newFilters).some(
      (key) =>
        key !== "page" &&
        key !== "searchQuery" &&
        newFilters[key as keyof ViewingRequestFilters] !==
          filters[key as keyof ViewingRequestFilters],
    )

    const finalFilters = hasOtherFilterChanges
      ? { ...newFilters, page: 1 }
      : newFilters

    setSearchParams(encodeFilters(finalFilters))
  }

  return (
    <>
      <Header title="Viewing Requests" />
      <Container>
        <ViewingRequestFiltersPanel
          filters={filters}
          onChange={handleChangeFilters}
        />
        <Table
          items={viewingRequests.map((v) => {
            return { ...v, id: `${v.buyerId}/${v.propertyId}` }
          })}
          getItemUrl={(viewingRequest) =>
            `/viewing-requests/${viewingRequest.id}/`
          }
          paginationData={pagination}
          baseUrl="/viewing-requests/"
          searchQuery={filters.searchQuery}
          onSearch={(value: string) => {
            setSearchParams(
              encodeFilters({ ...filters, searchQuery: value, page: 1 }),
            )
          }}
        >
          {(item) => <ViewingRequestRow item={item} />}
        </Table>
      </Container>
    </>
  )
}
