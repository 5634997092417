import { configureStore } from "@reduxjs/toolkit"
import { availabilityListReducer } from "./slices/availability-list"
import { listingAppointmentDetailsReducer } from "./slices/listing-appointment-details"
import { listingAppointmentsListReducer } from "./slices/listing-appointments-list"
import { offerDetailsReducer } from "./slices/offer-details"
import { offerListReducer } from "./slices/offer-list"
import { propertyDetailsReducer } from "./slices/property-details"
import { propertyListReducer } from "./slices/property-list"
import { userReducer } from "./slices/user"
import { userDetailsReducer } from "./slices/user-details"
import { viewingDetailsReducer } from "./slices/viewing-details"
import { viewingListReducer } from "./slices/viewing-list"
import { neighbourhoodsListReducer } from "./slices/neighbourhoods-list"
import { neighbourhoodDetailsReducer } from "./slices/neighbourhood-details"
import { waitlistedBuyerListReducer } from "./slices/waitlisted-buyer-list"
import { waitlistedBuyerDetailsReducer } from "./slices/waitlisted-buyer-details"
import { viewingRequestDetailsReducer } from "./slices/viewing-request-details"
import { viewingRequestListReducer } from "./slices/viewing-requests-list"

export const store = configureStore({
  reducer: {
    availabilityList: availabilityListReducer,
    listingAppointmentDetails: listingAppointmentDetailsReducer,
    listingAppointmentsList: listingAppointmentsListReducer,
    offerDetails: offerDetailsReducer,
    offerList: offerListReducer,
    propertyDetails: propertyDetailsReducer,
    propertyList: propertyListReducer,
    user: userReducer,
    userDetails: userDetailsReducer,
    viewingRequestDetails: viewingRequestDetailsReducer,
    viewingRequestList: viewingRequestListReducer,
    viewingDetails: viewingDetailsReducer,
    viewingList: viewingListReducer,
    neighbourhoodsList: neighbourhoodsListReducer,
    neighbourhoodDetails: neighbourhoodDetailsReducer,
    waitlistedBuyerList: waitlistedBuyerListReducer,
    waitlistedBuyerDetails: waitlistedBuyerDetailsReducer,
  },
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch
