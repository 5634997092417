import { ReactElement, useState, useEffect } from "react"
import { TextField } from "../../../global/forms/text-field"
import { BaseModal } from "./base-modal"

type EditNoteModalProps = {
  action?: { id: string; note: string }
  open?: boolean
  submitting?: boolean
  onSubmit: (id: string, note: string) => void
  onCancel: () => void
}

export const EditNoteModal: React.FC<EditNoteModalProps> = ({
  action,
  open,
  submitting,
  onSubmit,
  onCancel,
}): ReactElement => {
  const [internalValue, setInternalValue] = useState("")

  useEffect(() => {
    if (!action) return

    setInternalValue(action.note)
  }, [action])

  return (
    <BaseModal
      title="Edit note"
      submitLabel="Save"
      open={open}
      submitting={submitting}
      onSubmit={() => {
        if (action?.id && internalValue) {
          onSubmit(action.id, internalValue)
        }
      }}
      onCancel={onCancel}
    >
      <TextField
        value={internalValue}
        onChange={(value) => setInternalValue(value ?? "")}
        rows={8}
        className="w-full"
      />
    </BaseModal>
  )
}
