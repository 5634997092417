import { AdminRequestDetailsResponse_ActivityItem } from "../../../../ostrich/rpc/users/viewings/v2_pb"
import { ActivityItemDetails } from "../activity-item"
import { BaseModal } from "./base-modal"

interface RejectModalProps {
  action?: {
    item: AdminRequestDetailsResponse_ActivityItem
    emailsBuyer: string[]
    emailsSeller: string[]
  }
  open?: boolean
  onSubmit: (actionId: string) => void
  onCancel: () => void
}

export const RejectModal: React.FC<RejectModalProps> = ({
  action,
  open,
  onSubmit,
  onCancel,
}) => {
  if (!action) {
    return null
  }

  return (
    <BaseModal
      title="Confirm rejection"
      submitLabel="Reject"
      open={open}
      submitting={false}
      onSubmit={() => {
        if (action) onSubmit(action.item.id)
      }}
      onCancel={onCancel}
    >
      <div className="border p-2 bg-white rounded">
        <ActivityItemDetails item={action.item} />
      </div>
    </BaseModal>
  )
}
