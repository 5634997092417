import { ReactElement, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { DateField } from "../../global/forms/date-field"
import "react-datepicker/dist/react-datepicker.css"
import { formatDateWithPadding } from "../../../util/dates"
import {
  lookupAddress,
  updateAddress,
  updateField,
} from "../../../redux/slices/property-details"
import { NeighbourhoodStatus, Property } from "../../../types/property"
import { formatAddress } from "../../../util/addresses"
import { getSiteUrl } from "../../../util/api"
import { buildingTypes } from "../../../util/misc"
import { BooleanField } from "../../global/forms/boolean-field"
import { FormPanel } from "../../global/forms/form-panel"
import { SelectField } from "../../global/forms/select-field"
import { TextField } from "../../global/forms/text-field"
import { ListingAppointment } from "../../../types/listingAppointment"
import { MultipleSelectField } from "../../global/forms/multiple-select-field"
import { FieldTitle } from "../../global/forms/field-title"

type Props = {
  property: Property
  listingAppointment?: ListingAppointment
}

export const PropertyDetailsTab: React.FC<Props> = ({
  property,
  listingAppointment,
}): ReactElement => {
  const { errors } = useAppSelector((state) => state.propertyDetails)

  const dispatch = useAppDispatch()

  const [postcode, setPostcode] = useState(property.address?.postcode)

  useEffect(() => {
    setPostcode(property.address?.postcode)
  }, [property.address?.postcode])

  const { addressOptions, neighbourhoodOptions } = useAppSelector(
    (state) => state.propertyDetails
  )

  const showLeaseFields =
    property.attributes.tenure &&
    ["L", "R", "S"].includes(property.attributes.tenure)

  let listingAppointmentAddress: string | null = null

  if (listingAppointment?.address) {
    listingAppointmentAddress = listingAppointment.address.line1

    if (listingAppointment.address.line2) {
      listingAppointmentAddress += `, ${listingAppointment.address.line2}`
    }
  }

  return (
    <>
      <FormPanel title="Core Details">
        <TextField
          id="postcode"
          title="Postcode"
          value={postcode ?? ""}
          error={errors?.postcode}
          onChange={(value) => {
            setPostcode(value)
          }}
          onBlur={(value) => {
            if (!value) return

            dispatch(lookupAddress(value))
          }}
        />
        <SelectField
          id="address"
          title="Address"
          subtitle={
            listingAppointmentAddress
              ? ` User Submitted: ${listingAppointmentAddress}`
              : undefined
          }
          options={addressOptions.map((address) => {
            return {
              label: formatAddress(address),
              value: address.id,
            }
          })}
          placeholder="Select Address"
          value={property.address?.id}
          error={errors?.address}
          onChange={(value) => {
            dispatch(updateAddress(value))
          }}
        />
        <TextField
          id="display_address"
          title="Display Address"
          value={property.displayAddress ?? ""}
          error={errors?.displayAddress}
          onChange={(value) =>
            dispatch(updateField({ field: "displayAddress", value }))
          }
        />
        <TextField
          id="friendly_address"
          title="Friendly Address"
          value={property.friendlyAddress ?? ""}
          error={errors?.friendlyAddress}
          extraActions={
            property.displayAddress
              ? [
                  {
                    key: "copy-from-address",
                    label: "Copy from Address",
                  },
                ]
              : undefined
          }
          onChange={(value) =>
            dispatch(updateField({ field: "friendlyAddress", value }))
          }
          onExtraAction={() => {
            if (!property.address) return

            dispatch(
              updateField({
                field: "friendlyAddress",
                value: formatAddress(property.address, true),
              })
            )
          }}
        />
        <div className="col-span-2 md:col-span-1">
          <label className="block text-sm font-medium text-gray-700">URL</label>
          <div className="mt-1">
            {property.slug ? (
              <a
                href={`${getSiteUrl()}/properties/${property.slug}/`}
                className="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                target="_blank"
              >
                {`${getSiteUrl()}/properties/${property.slug}/`}
              </a>
            ) : (
              <span className="block w-full rounded-md border border-gray-300 px-3 py-2 text-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                &nbsp;
              </span>
            )}
          </div>
        </div>
        <SelectField
          id="status"
          title="Status"
          options={[
            { value: "L", label: "Live" },
            { value: "H", label: "Hidden" },
          ]}
          value={property.status}
          error={errors?.status}
          onChange={(value) => {
            dispatch(updateField({ field: "status", value }))
          }}
        />
        <SelectField
          id="neighbourhood"
          title="Primary Neighbourhood"
          options={neighbourhoodOptions.map((neighbourhood) => {
            return {
              value: neighbourhood.id,
              label:
                neighbourhood.name +
                (neighbourhood.status === NeighbourhoodStatus.Hidden
                  ? " (Hidden)"
                  : ""),
            }
          })}
          value={property.neighbourhoodId}
          error={errors?.neighbourhood}
          onChange={(value) => {
            dispatch(updateField({ field: "neighbourhoodId", value }))
          }}
        />
        <MultipleSelectField
          title="Secondary Neighbourhoods"
          options={neighbourhoodOptions.map((neighbourhood) => {
            return {
              value: neighbourhood.id,
              label:
                neighbourhood.name +
                (neighbourhood.status === NeighbourhoodStatus.Hidden
                  ? " (Hidden)"
                  : ""),
            }
          })}
          value={property.secondaryNeighbourhoodIds ?? []}
          error={errors?.neighbourhood}
          onChange={(value) => {
            dispatch(updateField({ field: "secondaryNeighbourhoodIds", value }))
          }}
        />
        {property.status === "H" && (
          <>
            <SelectField
              id="reason_for_hiding"
              title="Reason for Hiding (Homeowner)"
              options={[
                { value: "NO", label: "I no longer wish to receive offers" },
                {
                  value: "OM",
                  label:
                    "I have decided to sell my property on the open market",
                },
                { value: "EA", label: "I have appointed an estate agent" },
                {
                  value: "NI",
                  label: "I am no longer interested in selling my home",
                },
                { value: "OT", label: "Other" },
              ]}
              value={property.hideReason}
              className="col-span-1"
              onChange={(value) =>
                dispatch(updateField({ field: "hideReason", value }))
              }
            />
            {property.hideReason === "OT" && (
              <TextField
                id="reason_for_hiding_extra"
                title="Reason for Hiding (Homeowner) - Description"
                rows={3}
                value={property.hideReasonExtra}
                onChange={(value) =>
                  dispatch(updateField({ field: "hideReasonExtra", value }))
                }
                className="col-span-1"
              />
            )}
          </>
        )}
        <SelectField
          id="sale_timeline"
          title="Sale Timeline"
          options={[
            { value: "0-12", label: "0-12 months" },
            { value: "12+", label: "12+ months" },
          ]}
          value={property.saleTimeline}
          onChange={(value) =>
            dispatch(updateField({ field: "saleTimeline", value }))
          }
        />
        <TextField
          id="sale_timeline_notes"
          title="Sale Timeline Notes"
          rows={3}
          value={property.saleTimelineNotes}
          onChange={(value) =>
            dispatch(updateField({ field: "saleTimelineNotes", value }))
          }
          className="col-span-1"
        />
        <BooleanField
          title="Flags"
          label="Featured"
          checked={property.featured}
          onChange={(checked) =>
            dispatch(updateField({ field: "featured", value: checked }))
          }
        />
        <TextField
          id="intro"
          title="Introduction"
          rows={4}
          value={property.intro}
          error={errors?.intro}
          onChange={(value) => dispatch(updateField({ field: "intro", value }))}
        />
      </FormPanel>
      <FormPanel title="Pricing">
        <TextField
          id="guide_price"
          title="Price Guide"
          value={property.guidePrice ? String(property.guidePrice) : ""}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "guidePrice",
                value: isNaN(parseInt(value ?? "")) ? 0 : parseInt(value ?? ""),
              })
            )
          }}
        />
        <SelectField
          id="auto_reject_level"
          title="Auto-Reject Offers Below"
          options={[
            { label: "Guide price", value: "100" },
            { label: "-10%", value: "90" },
            { label: "-20%", value: "80" },
            { label: "Send all offers" },
          ]}
          value={
            property.autoRejectLevel
              ? String(property.autoRejectLevel)
              : undefined
          }
          onChange={(value) => {
            dispatch(
              updateField({
                field: "autoRejectLevel",
                value: value ? parseInt(value) : undefined,
              })
            )
          }}
        />
      </FormPanel>
      <FormPanel title="Council Details">
        <SelectField
          id="council_tax_band"
          title="Council Tax Band"
          options={[
            { value: "A", label: "A" },
            { value: "B", label: "B" },
            { value: "C", label: "C" },
            { value: "D", label: "D" },
            { value: "E", label: "E" },
            { value: "F", label: "F" },
            { value: "G", label: "G" },
            { value: "H", label: "H" },
          ]}
          value={property.councilTaxBand}
          showClearButton
          onChange={(value) => {
            dispatch(
              updateField({
                field: "councilTaxBand",
                value,
              })
            )
          }}
        />
        <SelectField
          id="local_authority"
          title="Local Authority"
          className="order-first col-span-2 sm:order-none md:col-span-1"
          options={[
            { value: "IS", label: "Islington" },
            { value: "HC", label: "Hackney" },
            { value: "CA", label: "Camden" },
            { value: "HR", label: "Haringey" },
            { value: "TH", label: "Tower Hamlets" },
          ]}
          value={property.localAuthority}
          error={errors?.localAuthority}
          onChange={(value) => {
            dispatch(updateField({ field: "localAuthority", value }))
          }}
        />
      </FormPanel>
      <FormPanel title="Attributes &amp; Amenities">
        <SelectField
          id="tenure"
          title="Tenure"
          options={[
            { value: "F", label: "Freehold" },
            { value: "L", label: "Leasehold" },
            { value: "R", label: "Reverse Freehold" },
            { value: "S", label: "Share of Freehold" },
          ]}
          value={property.attributes.tenure}
          error={errors?.attributes?.tenure}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, tenure: value },
              })
            )
          }}
        />
        <TextField
          id="interior_size"
          title="Interior Size"
          value={
            property.attributes.interiorSize
              ? String(property.attributes.interiorSize)
              : ""
          }
          error={errors?.attributes?.interiorSize}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  interiorSize: isNaN(parseInt(value ?? ""))
                    ? 0
                    : parseInt(value ?? ""),
                },
              })
            )
          }}
        />
        <SelectField
          id="building_type"
          title="Building Type"
          options={buildingTypes.map((t) => ({ value: t, label: t }))}
          value={property.attributes.buildingType}
          error={errors?.attributes?.buildingType}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, buildingType: value },
              })
            )
          }}
        />
        <SelectField
          id="internal_type"
          title="Internal Type"
          options={[
            { value: "F", label: "Flat" },
            { value: "H", label: "House" },
          ]}
          value={property.attributes.internalType}
          error={errors?.attributes?.internalType}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, internalType: value },
              })
            )
          }}
        />
        <TextField
          id="outdoor_space"
          title="Outdoor Space"
          value={property.attributes.outdoorSpace}
          error={errors?.attributes?.outdoorSpace}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, outdoorSpace: value },
              })
            )
          }}
        />
        <TextField
          id="parking"
          title="Parking"
          value={property.attributes.parking}
          error={errors?.attributes?.parking}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, parking: value },
              })
            )
          }}
        />
        <TextField
          id="floors"
          title="Floors"
          value={property.attributes.floors}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, floors: value },
              })
            )
          }}
        />
        <TextField
          id="bedrooms"
          title="Bedrooms"
          value={
            property.attributes.bedrooms
              ? String(property.attributes.bedrooms)
              : "0"
          }
          error={errors?.attributes?.bedrooms}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  bedrooms: isNaN(parseInt(value ?? ""))
                    ? 0
                    : parseInt(value ?? ""),
                },
              })
            )
          }}
        />
        <TextField
          id="bathrooms"
          title="Bathrooms"
          value={
            property.attributes.bathrooms
              ? String(property.attributes.bathrooms)
              : ""
          }
          error={errors?.attributes?.bathrooms}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  bathrooms: isNaN(parseInt(value ?? ""))
                    ? 0
                    : parseInt(value ?? ""),
                },
              })
            )
          }}
        />
        <TextField
          id="listed_status"
          title="Listed Status"
          value={property.attributes.listedStatus}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, listedStatus: value },
              })
            )
          }}
        />
        <TextField
          id="utilities_electric"
          title="Electric (Utilities)"
          value={property.attributes.utilitiesElectric}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, utilitiesElectric: value },
              })
            )
          }}
        />
        <TextField
          id="utilities_water"
          title="Water (Utilities)"
          value={property.attributes.utilitiesWater}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, utilitiesWater: value },
              })
            )
          }}
        />
        <TextField
          id="utilities_broadband"
          title="Broadband (Utilities)"
          value={property.attributes.utilitiesBroadband}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, utilitiesBroadband: value },
              })
            )
          }}
        />
        <TextField
          id="utilities_heating"
          title="Heating (Utilities)"
          value={property.attributes.utilitiesHeating}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: { ...property.attributes, utilitiesHeating: value },
              })
            )
          }}
        />
        {showLeaseFields && (
          <>
            <TextField
              id="lease_length"
              title="Lease Length"
              value={property.attributes.leaseLength ?? ""}
              onChange={(value) => {
                dispatch(
                  updateField({
                    field: "attributes",
                    value: {
                      ...property.attributes,
                      leaseLength: value,
                    },
                  })
                )
              }}
            />
            <TextField
              id="ground_rent"
              title="Ground Rent"
              value={property.attributes.groundRent ?? ""}
              onChange={(value) => {
                dispatch(
                  updateField({
                    field: "attributes",
                    value: {
                      ...property.attributes,
                      groundRent: value,
                    },
                  })
                )
              }}
            />
            <TextField
              id="service_charge"
              title="Service Charge"
              value={property.attributes.serviceCharge ?? ""}
              onChange={(value) => {
                dispatch(
                  updateField({
                    field: "attributes",
                    value: {
                      ...property.attributes,
                      serviceCharge: value,
                    },
                  })
                )
              }}
            />
          </>
        )}
      </FormPanel>
      <FormPanel title="Filters" className="grid grid-cols-3 gap-6">
        <BooleanField
          label="Freehold"
          checked={property.attributes.filterFreehold ?? false}
          onChange={(checked) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  filterFreehold: checked,
                },
              })
            )
          }}
        />
        <BooleanField
          label="Parking"
          checked={property.attributes.filterParking ?? false}
          onChange={(checked) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  filterParking: checked,
                },
              })
            )
          }}
        />
        <BooleanField
          label="Outdoor Space"
          checked={property.attributes.filterOutdoorSpace ?? false}
          onChange={(checked) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  filterOutdoorSpace: checked,
                },
              })
            )
          }}
        />
      </FormPanel>
      <FormPanel title="EPC">
        <TextField
          id="epc_current"
          title="Current Rating"
          error={errors?.attributes?.epcCurrent}
          value={
            property.attributes.epcCurrent
              ? String(property.attributes.epcCurrent)
              : ""
          }
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  epcCurrent: isNaN(parseInt(value ?? ""))
                    ? 0
                    : parseInt(value ?? ""),
                },
              })
            )
          }}
        />
        <TextField
          id="epc_potential"
          title="Potential Rating"
          error={errors?.attributes?.epcPotential}
          value={
            property.attributes.epcPotential
              ? String(property.attributes.epcPotential)
              : ""
          }
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  epcPotential: isNaN(parseInt(value ?? ""))
                    ? 0
                    : parseInt(value ?? ""),
                },
              })
            )
          }}
        />
        <TextField
          id="epc_url"
          title="EPC URL"
          className="col-span-2"
          value={property.attributes.epcUrl ?? ""}
          error={errors?.attributes?.epcUrl}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  epcUrl: value,
                },
              })
            )
          }}
        />
        <DateField
          title="EPC Expiry Date"
          value={property.attributes.epcExpiryDate}
          showTimeSelect={false}
          isClearable={true}
          onChange={(value) => {
            dispatch(
              updateField({
                field: "attributes",
                value: {
                  ...property.attributes,
                  epcExpiryDate: value
                    ? formatDateWithPadding(value)
                    : undefined,
                },
              })
            )
          }}
        />
        {listingAppointment?.notes && (
          <div className="col-span-2">
            <FieldTitle
              title="Booking Notes"
              extraActions={[
                {
                  key: "view-booking",
                  label: "View Booking",
                  href: `/bookings/${listingAppointment.id}/`,
                  external: true,
                },
              ]}
            />
            <p className="text-sm">{listingAppointment.notes}</p>
          </div>
        )}
      </FormPanel>
    </>
  )
}
