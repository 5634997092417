import { useEffect, useState } from "react"
import { BaseModal } from "./base-modal"
import { AdminRequestDetailsResponse_ActivityItem } from "../../../../ostrich/rpc/users/viewings/v2_pb"
import { ActivityItemDetails } from "../activity-item"

interface ApproveModalProps {
  action?: {
    item: AdminRequestDetailsResponse_ActivityItem
    emailsBuyer: string[]
    emailsSeller: string[]
  }
  verified?: boolean
  open?: boolean
  onSubmit: (
    actionId: string,
    sendBuyerEmails?: boolean,
    sendSellerEmails?: boolean,
  ) => void
  onCancel: () => void
}

export const ApproveModal: React.FC<ApproveModalProps> = ({
  action,
  verified,
  open,
  onSubmit,
  onCancel,
}) => {
  const [sendBuyerEmails, setSendBuyerEmails] = useState(false)
  const [sendSellerEmails, setSendSellerEmails] = useState(false)

  useEffect(() => {
    if (!action) return

    setSendBuyerEmails(action.emailsBuyer.length > 0)
    setSendSellerEmails(action.emailsSeller.length > 0)
  }, [action])

  if (!action) {
    return null
  }

  return (
    <BaseModal
      title="Confirm approval"
      submitLabel="Approve"
      alert={verified ? undefined : "Unverified buyer"}
      open={open}
      submitting={false}
      onSubmit={() => {
        if (action) onSubmit(action.item.id, sendBuyerEmails, sendSellerEmails)
      }}
      onCancel={onCancel}
    >
      <div className="border p-2 bg-white rounded">
        <ActivityItemDetails item={action.item} />
      </div>
      {action?.emailsBuyer && action.emailsBuyer.length > 0 && (
        <div className="flex gap-x-2">
          <div className="flex h-6 shrink-0 items-center">
            <div className="group grid size-4 grid-cols-1">
              <input
                type="checkbox"
                id="buyer-emails"
                checked={sendBuyerEmails}
                onChange={(e) => setSendBuyerEmails(e.target.checked)}
                className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
              />
              <svg
                fill="none"
                viewBox="0 0 14 14"
                className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="opacity-0 group-has-[:checked]:opacity-100"
                />
                <path
                  d="M3 7H11"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="opacity-0 group-has-[:indeterminate]:opacity-100"
                />
              </svg>
            </div>
          </div>
          <div className="text-sm/6 flex-grow">
            <label htmlFor="buyer-emails" className="font-medium text-gray-900">
              Send buyer emails
            </label>
            <ul className="text-gray-500">
              {action.emailsBuyer.map((email, i) => (
                <li key={`email-${i}`}>{email}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
      {action?.emailsSeller && action.emailsSeller.length > 0 && (
        <div className="flex gap-x-2">
          <div className="flex h-6 shrink-0 items-center">
            <div className="group grid size-4 grid-cols-1">
              <input
                type="checkbox"
                id="seller-emails"
                checked={sendSellerEmails}
                onChange={(e) => setSendSellerEmails(e.target.checked)}
                className="col-start-1 row-start-1 appearance-none rounded border border-gray-300 bg-white checked:border-indigo-600 checked:bg-indigo-600 indeterminate:border-indigo-600 indeterminate:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:checked:bg-gray-100 forced-colors:appearance-auto"
              />
              <svg
                fill="none"
                viewBox="0 0 14 14"
                className="pointer-events-none col-start-1 row-start-1 size-3.5 self-center justify-self-center stroke-white group-has-[:disabled]:stroke-gray-950/25"
              >
                <path
                  d="M3 8L6 11L11 3.5"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="opacity-0 group-has-[:checked]:opacity-100"
                />
                <path
                  d="M3 7H11"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="opacity-0 group-has-[:indeterminate]:opacity-100"
                />
              </svg>
            </div>
          </div>
          <div className="text-sm/6">
            <label
              htmlFor="seller-emails"
              className="font-medium text-gray-900"
            >
              Send seller emails
            </label>
            <ul className="text-gray-500">
              {action.emailsSeller.map((email, i) => (
                <li key={`email-${i}`}>{email}</li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </BaseModal>
  )
}
