import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { getEndpoint } from "../../util/api"
import {
  AdminRequestDetailsResponse,
  AdminRequestDetailsResponse_ActivityItem,
} from "../../ostrich/rpc/users/viewings/v2_pb"

type State = {
  viewingRequest?: AdminRequestDetailsResponse
  reviewAction?: {
    item: AdminRequestDetailsResponse_ActivityItem
    emailsBuyer: string[]
    emailsSeller: string[]
  }
  editNoteAction?: {
    id: string
    note: string
  }
  editViewingData?: {
    id: string
    dateViewing: string
    additionalAttendees?: string
  }
  modals: ModalState
  submitting: boolean
}

export type ModalState = {
  editInitialRequest?: boolean
  editNote?: boolean
  editViewing?: boolean
  approve?: boolean
  reject?: boolean
}

const initialState: State = {
  modals: {},
  submitting: false,
}

export const fetchViewingRequest = createAsyncThunk(
  "viewingRequestDetails/fetchViewingRequest",
  async (data: { buyerId: string; propertyId: string }, thunkAPI) => {
    const response = await fetch(
      `${getEndpoint()}/viewing-requests/${data.buyerId}/${data.propertyId}/`,
      {
        cache: "no-cache",
        credentials: "include",
      },
    )

    const json = (await response.json()) as AdminRequestDetailsResponse

    return json
  },
)

export const review = createAsyncThunk(
  "viewingRequestDetails/review",
  async (
    data: {
      buyerId: string
      propertyId: string
      actionId: string
      approved: boolean
      sendBuyerEmails?: boolean
      sendSellerEmails?: boolean
    },
    thunkAPI,
  ) => {
    const response = await fetch(
      `${getEndpoint()}/viewing-requests/${data.buyerId}/${data.propertyId}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          operation: "review",
          actionId: data.actionId,
          approved: data.approved,
          sendBuyerEmails: data.sendBuyerEmails,
          sendSellerEmails: data.sendSellerEmails,
        }),
      },
    )

    const json = (await response.json()) as AdminRequestDetailsResponse

    return json
  },
)

export const editInitialRequest = createAsyncThunk(
  "viewingRequestDetails/editInitialRequest",
  async (
    data: {
      buyerId: string
      propertyId: string
      indicativeOffer: number
      note: string
      bioBuyer: string
    },
    thunkAPI,
  ) => {
    const response = await fetch(
      `${getEndpoint()}/viewing-requests/${data.buyerId}/${data.propertyId}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          operation: "edit-initial-request",
          indicativeOffer: data.indicativeOffer,
          note: data.note,
          bioBuyer: data.bioBuyer,
        }),
      },
    )

    const json = (await response.json()) as AdminRequestDetailsResponse

    return json
  },
)

export const editNote = createAsyncThunk(
  "viewingRequestDetails/editNote",
  async (
    data: {
      buyerId: string
      propertyId: string
      actionId: string
      note: string
    },
    thunkAPI,
  ) => {
    const response = await fetch(
      `${getEndpoint()}/viewing-requests/${data.buyerId}/${data.propertyId}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          operation: "edit-note",
          actionId: data.actionId,
          note: data.note,
        }),
      },
    )

    const json = (await response.json()) as AdminRequestDetailsResponse

    return json
  },
)

export const editViewing = createAsyncThunk(
  "viewingRequestDetails/editViewing",
  async (
    data: {
      buyerId: string
      propertyId: string
      viewingId: string
      dateViewing: string
      additionalAttendees?: string
    },
    thunkAPI,
  ) => {
    const response = await fetch(
      `${getEndpoint()}/viewing-requests/${data.buyerId}/${data.propertyId}/`,
      {
        method: "POST",
        cache: "no-cache",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          operation: "edit-viewing",
          viewingId: data.viewingId,
          dateViewing: data.dateViewing,
          additionalAttendees: data.additionalAttendees,
        }),
      },
    )

    const json = (await response.json()) as AdminRequestDetailsResponse

    return json
  },
)

export const deleteViewingRequest = createAsyncThunk(
  "viewingRequestDetails/deleteViewingRequest",
  async (
    data: {
      buyerId: string
      propertyId: string
    },
    thunkAPI,
  ) => {
    const response = await fetch(
      `${getEndpoint()}/viewing-requests/${data.buyerId}/${data.propertyId}/`,
      {
        method: "DELETE",
        cache: "no-cache",
        credentials: "include",
      },
    )

    const json = (await response.json()) as AdminRequestDetailsResponse

    return json
  },
)

export const viewingRequestDetailsSlice = createSlice({
  name: "viewingRequestDetails",
  initialState,
  reducers: {
    startEditingInitialRequest: (state) => {
      state.modals.editInitialRequest = true
    },
    startEditingNote: (
      state,
      action: PayloadAction<{ id: string; note: string }>,
    ) => {
      state.editNoteAction = action.payload
      state.modals.editNote = true
    },
    startEditingViewing: (
      state,
      action: PayloadAction<{
        id: string
        dateViewing: string
        additionalAttendees?: string
      }>,
    ) => {
      state.editViewingData = action.payload
      state.modals.editViewing = true
    },
    startApprove: (
      state,
      action: PayloadAction<{
        item: AdminRequestDetailsResponse_ActivityItem
        emailsBuyer: string[]
        emailsSeller: string[]
      }>,
    ) => {
      state.reviewAction = action.payload
      state.modals.approve = true
    },
    startReject: (
      state,
      action: PayloadAction<{
        item: AdminRequestDetailsResponse_ActivityItem
        emailsBuyer: string[]
        emailsSeller: string[]
      }>,
    ) => {
      state.reviewAction = action.payload
      state.modals.reject = true
    },
    cancelEditingInitialRequest: (state) => {
      state.modals.editInitialRequest = false
    },
    cancelEditingNote: (state) => {
      state.modals.editNote = false
      delete state.editNoteAction
    },
    cancelEditingViewing: (state) => {
      state.modals.editViewing = false
      delete state.editViewingData
    },
    cancelApprove: (state) => {
      state.modals.approve = false
      delete state.reviewAction
    },
    cancelReject: (state) => {
      state.modals.reject = false
      delete state.reviewAction
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchViewingRequest.pending, (state, action) => {
        // TODO: Clear current state if we're fetching
        // for a different buyer ID or property ID
      })
      .addCase(fetchViewingRequest.fulfilled, (state, action) => {
        state.viewingRequest = action.payload
      })
      .addCase(review.pending, (state, action) => {
        state.submitting = true
      })
      .addCase(review.rejected, (state, action) => {
        state.submitting = false
        // TODO: Add error
      })
      .addCase(review.fulfilled, (state, action) => {
        state.viewingRequest = action.payload
        state.modals.approve = false
        state.modals.reject = false
        state.submitting = false
        delete state.reviewAction
      })
      .addCase(editInitialRequest.pending, (state, action) => {
        state.submitting = true
      })
      .addCase(editInitialRequest.rejected, (state, action) => {
        state.submitting = false
        // TODO: Add error
      })
      .addCase(editInitialRequest.fulfilled, (state, action) => {
        state.viewingRequest = action.payload
        state.modals.editInitialRequest = false
        state.submitting = false
      })
      .addCase(editNote.pending, (state, action) => {
        state.submitting = true
      })
      .addCase(editNote.rejected, (state, action) => {
        state.submitting = false
      })
      .addCase(editNote.fulfilled, (state, action) => {
        state.viewingRequest = action.payload
        state.modals.editNote = false
        state.submitting = false
        delete state.editNoteAction
      })
      .addCase(editViewing.pending, (state, action) => {
        state.submitting = true
      })
      .addCase(editViewing.rejected, (state, action) => {
        state.submitting = false
      })
      .addCase(editViewing.fulfilled, (state, action) => {
        state.viewingRequest = action.payload
        state.modals.editViewing = false
        state.submitting = false
        delete state.editViewingData
      })
      .addCase(deleteViewingRequest.fulfilled, (state, action) => {
        state.viewingRequest = action.payload
      })
  },
})

export const viewingRequestDetailsReducer = viewingRequestDetailsSlice.reducer
export const {
  cancelApprove,
  cancelEditingInitialRequest,
  cancelEditingNote,
  cancelEditingViewing,
  cancelReject,
  startApprove,
  startEditingInitialRequest,
  startEditingNote,
  startEditingViewing,
  startReject,
} = viewingRequestDetailsSlice.actions
