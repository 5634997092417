import { ReactElement } from "react"
import { AdminListRequestsResponse_ListItem } from "../../../ostrich/rpc/users/viewings/v2_pb"
import {
  CurrencyPoundIcon,
  HomeIcon,
  PencilAltIcon,
} from "@heroicons/react/outline"
import { formatDateTime } from "../../../util/dates"
import { ItemDates } from "../../global/tables/item-dates"

export const ViewingRequestRow: React.FC<{
  item: AdminListRequestsResponse_ListItem
}> = ({ item }): ReactElement => {
  return (
    <div className="h-12 min-w-0 flex-1 md:grid md:grid-cols-3 md:gap-4">
      <div>
        <p className="flex items-center gap-2 truncate text-sm font-medium text-blue-600">
          {item.buyerName}
          <span className="inline-flex items-center rounded-full text-xs font-medium px-2.5 py-1 bg-gray-100 text-gray-800">
            {item.status}
          </span>
          {!item.verified && (
            <span
              key="unverified"
              className="inline-flex items-center rounded-full bg-orange-100 px-2.5 py-0.5 text-xs font-medium text-orange-800"
            >
              Unverified
            </span>
          )}
        </p>
      </div>
      <div className="hidden md:block">
        <div>
          <ul className="flex flex-col gap-2 text-sm text-gray-500">
            <li className="flex">
              <HomeIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {item.propertyAddress}
            </li>
            <li className="flex">
              <CurrencyPoundIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              £{item.indicativeOffer.toLocaleString()}
            </li>
          </ul>
        </div>
      </div>
      <div className="flex text-sm text-gray-500">
        <ItemDates item={item} />
      </div>
    </div>
  )
}
