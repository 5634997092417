import {
  AnnotationIcon,
  CalendarIcon,
  CheckIcon,
  CurrencyPoundIcon,
  InboxInIcon,
  PencilIcon,
  ReplyIcon,
  XIcon,
} from "@heroicons/react/solid"
import {
  AdminRequestDetailsResponse_ActivityIcon,
  AdminRequestDetailsResponse_ActivityItem,
  AdminRequestDetailsResponse_AdminAction,
} from "../../../ostrich/rpc/users/viewings/v2_pb"
import { createElement } from "react"
import { formatDateTime } from "../../../util/dates"
import { ActionButton } from "./action-button"

const getIconClass = (item: AdminRequestDetailsResponse_ActivityItem) => {
  switch (item.icon) {
    case AdminRequestDetailsResponse_ActivityIcon.ADMIN_APPROVE:
      return CheckIcon

    case AdminRequestDetailsResponse_ActivityIcon.ADMIN_REJECT:
      return XIcon

    case AdminRequestDetailsResponse_ActivityIcon.ADMIN_EDIT:
      return PencilIcon

    case AdminRequestDetailsResponse_ActivityIcon.NEW:
      return InboxInIcon

    case AdminRequestDetailsResponse_ActivityIcon.REPLY:
      return ReplyIcon

    case AdminRequestDetailsResponse_ActivityIcon.CALENDAR:
      return CalendarIcon

    case AdminRequestDetailsResponse_ActivityIcon.OFFER:
      return CurrencyPoundIcon

    default:
      return AnnotationIcon
  }
}

const formatTitle = (title: string, date: string) => {
  return (
    title.replace(
      /\*([^\*]*)\*/g,
      '<span class="font-medium text-gray-900">$1</span>',
    ) +
    `&nbsp;<span class="text-gray-400">&bull;</span>&nbsp;${formatDateTime(date)}`
  )
}

const getContent = (item: AdminRequestDetailsResponse_ActivityItem) => {
  if (item.content?.message) {
    return item.content.message
      .split("\n")
      .map((line, i) => <p key={`line-${i}`}>{line}</p>)
  }

  if (item.content?.fields) {
    return (
      <div className="flex flex-col gap-y-1">
        {item.content.fields.map((field, i) => (
          <div key={`field-${i}`}>
            <h3 className="mb-1 text-xs font-medium text-gray-500">
              {field.title}
            </h3>
            {field.value.split("\n").map((line, i) => (
              <p key={`line-${i}`}>{line}</p>
            ))}
          </div>
        ))}
      </div>
    )
  }
}

interface ActivityItemProps {
  item: AdminRequestDetailsResponse_ActivityItem
  onAction: (
    item: AdminRequestDetailsResponse_ActivityItem,
    action: AdminRequestDetailsResponse_AdminAction,
  ) => void
}

export const ActivityItem: React.FC<ActivityItemProps> = ({
  item,
  onAction,
}) => {
  const isAdmin = [
    AdminRequestDetailsResponse_ActivityIcon.ADMIN_APPROVE,
    AdminRequestDetailsResponse_ActivityIcon.ADMIN_REJECT,
    AdminRequestDetailsResponse_ActivityIcon.ADMIN_EDIT,
  ].includes(item.icon)

  return (
    <li className="group">
      <div className={`relative pt-8 group-first:pt-0`}>
        <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200 group-last:hidden" />
        <div className={`relative flex items-start space-x-3`}>
          <>
            <div>
              <div className="relative px-1">
                <div
                  className={`flex items-center justify-center rounded-full ring-8 ring-white ${
                    isAdmin
                      ? "mx-1.5 mt-1.5 h-5 w-5 bg-gray-100"
                      : "h-8 w-8 bg-gray-400"
                  }`}
                >
                  {createElement(getIconClass(item), {
                    className: `${
                      isAdmin ? "text-gray-500 h-3" : "text-white h-5"
                    }`,
                  })}
                </div>
              </div>
            </div>
            <div
              className={`min-w-0 flex-1 py-1.5 ${
                item.adminRejected
                  ? "line-through decoration-gray-500/30 decoration-2"
                  : ""
              }`}
            >
              <ActivityItemDetails item={item} />
              <div className="mt-2 flex gap-x-2">
                {item.actions.map((action) => (
                  <ActionButton
                    key={`action-${action.type}`}
                    type={action.type}
                    onClick={() => onAction(item, action)}
                  />
                ))}
              </div>
            </div>
          </>
        </div>
      </div>
    </li>
  )
}

export const ActivityItemDetails: React.FC<{
  item: AdminRequestDetailsResponse_ActivityItem
}> = ({ item }) => {
  return (
    <>
      <div>
        <div
          className="text-sm text-gray-500"
          dangerouslySetInnerHTML={{
            __html: formatTitle(item.title, item.date),
          }}
        />
      </div>
      <div className="mt-2 text-sm text-gray-700">{getContent(item)}</div>
    </>
  )
}
