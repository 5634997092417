import { ReactElement } from "react"
import { FilterOption } from "../global/filter-option"
import { ViewingFilters } from "../../redux/slices/viewing-list"

type Props = {
  filters: ViewingFilters
  onChange: (filters: ViewingFilters) => void
}

export const ViewingFiltersPanel: React.FC<Props> = ({
  filters,
  onChange,
}): ReactElement => {
  const changeStatus = (status?: string) => {
    let updatedStatus: string[] = []

    if (status) {
      updatedStatus = [status]
    }

    onChange({ ...filters, status: updatedStatus })
  }

  return (
    <div className="flex flex-col items-center gap-0 bg-white text-sm text-gray-800 shadow sm:mb-6 sm:flex-row sm:gap-12 sm:rounded-md sm:px-6 lg:mb-8">
      <div className="flex items-center divide-x sm:flex-1">
        <span className="w-1/3 p-4 font-medium sm:w-auto sm:pl-0">Status</span>
        <FilterOption
          label="All"
          checked={!filters.status}
          onToggle={() => changeStatus()}
        />
        <FilterOption
          label="Confirmed"
          checked={Boolean(filters.status?.includes("CO"))}
          onToggle={() => changeStatus("CO")}
        />
        <FilterOption
          label="Cancelled"
          checked={Boolean(filters.status?.includes("CA"))}
          onToggle={() => changeStatus("CA")}
        />
      </div>
      <div className="flex items-center border-l">
        <FilterOption
          label="Include past viewings"
          checked={filters.includePastViewings}
          onToggle={() => {
            onChange({
              ...filters,
              includePastViewings: !filters.includePastViewings,
            })
          }}
        />
      </div>
    </div>
  )
}
